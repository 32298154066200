@import 'constants';
@import 'shared-styles';

// TODO: Get rid of these styles and try to stick to your common styles
// https://celum.atlassian.net/browse/SACC-3431
.sacc-form-field {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  line-height: normal !important;

  mat-label {
    @include label;
    margin-bottom: 0.4rem;
  }

  &.sacc-form-field--no-error {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-form-field,
  &.mat-mdc-form-field {
    line-height: 1.3;
    width: 100%;

    &-invalid.ng-touched {
      .mdc-notched-outline {
        &__leading,
        &__trailing,
        &__notch {
          border-color: $color-error !important;
        }
      }
    }

    &-infix {
      width: unset !important;
      min-height: unset !important;
      padding-top: 0.8rem !important;
      padding-bottom: 0.8rem !important;
    }

    .mdc-text-field {
      padding-left: 1.2rem;
      padding-right: 1.2rem;

      &__input {
        color: $color-blue-gray-900;
      }

      .mdc-notched-outline {
        &__leading,
        &__trailing,
        &__notch {
          border-color: $color-blue-gray-200 !important;
          border-width: 0.1rem !important;
        }

        &__leading {
          border-top-left-radius: 0.2rem !important;
          border-bottom-left-radius: 0.2rem !important;
        }

        &__trailing {
          border-top-right-radius: 0.2rem !important;
          border-bottom-right-radius: 0.2rem !important;
        }
      }

      &--invalid:not(&--disabled) {
        .mdc-notched-outline {
          &__leading,
          &__trailing,
          &__notch {
            border-color: $color-error !important;
          }
        }
      }

      &--focused:not(&--invalid) {
        .mdc-notched-outline {
          &__leading,
          &__trailing,
          &__notch {
            border-color: $color-blue-gray-600 !important;
          }
        }
      }

      &--disabled {
        opacity: 0.36 !important;
        color: unset !important;

        .mat-mdc-input-element {
          cursor: default !important;
          color: $color-blue-gray-900 !important;
        }

        .mdc-notched-outline {
          &__leading,
          &__trailing,
          &__notch {
            border-color: $color-blue-gray-600 !important;
          }
        }
      }
    }

    .mat-mdc-input-element {
      height: 1.8rem !important;
      font: $font-body;
      caret-color: $blue-gray-600 !important;

      &::placeholder {
        color: rgba($color-blue-gray-900, 0.5);
      }
    }

    &-subscript-wrapper {
      height: 1.8rem;

      mat-hint {
        font-size: 1.1rem;
        color: rgba($color-blue-gray-900, 0.56);
      }
    }

    .mat-mdc-form-field-bottom-align::before {
      height: unset !important;
    }

    &-error-wrapper,
    &-hint-wrapper {
      padding: 0;
      font-size: 1.1rem;

      .mat-mdc-form-field-error,
      .mat-mdc-form-field-hint {
        &::before {
          display: none;
        }
      }
    }
  }

  .mat-mdc-select {
    font-size: 1.3rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    &-value {
      margin-right: 1.2rem;
      line-height: 1.3;
    }

    &-arrow-wrapper {
      height: unset;

      .mat-mdc-select-arrow {
        border-left: 0.1rem solid $blue-gray-500 !important;
        border-right: none !important;
        border-top: 0.1rem solid $blue-gray-500 !important;
        rotate: 225deg;

        width: 0.5rem;
        height: 0.5rem;

        margin: 0.4rem;
        margin-top: 0;

        svg {
          display: none;
        }
      }
    }
  }
}

.mat-mdc-option {
  min-height: 3.2rem !important;
  line-height: 1.8rem !important;
  height: unset;

  .mdc-list-item__primary-text {
    white-space: nowrap !important;
  }

  &:not(.mdc-list-item--disabled):hover {
    background-color: rgba($color-blue-gray-900, 0.08) !important;
  }

  &:active {
    background-color: rgba($color-blue-gray-900, 0.16) !important;
  }

  &-text {
    font-size: 1.3rem;
    color: rgba($color-blue-gray-900, 0.94);
  }

  &-active {
    background-color: rgba($color-blue-gray-900, 0.12) !important;

    .mdc-list-item__primary-text {
      color: rgba($color-blue-gray-900, 0.94) !important;
    }
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $color-blue-gray-200;
  }
}

.mat-mdc-select {
  &-panel {
    background-color: white;
    padding: 0 !important;

    &:not([class*='mat-elevation-z']) {
      box-shadow:
        0 0.2rem 0.4rem -0.1rem #0003,
        0 0.4rem 0.5rem #00000024,
        0 0.1rem 1rem #0000001f;
    }

    .mat-mdc-option.mdc-list-item--selected:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      background: rgba(0, 0, 0, 0.12);
    }
  }
  &-placeholder {
    color: unset !important;
  }
}
