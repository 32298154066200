$font-family:
  Open Sans,
  sans serif;

@mixin get-font-config($font-family, $font-size, $line-height, $font-weight) {
  font-family: $font-family !important;
  font-size: $font-size !important;
  line-height: $line-height !important;
  font-weight: $font-weight;
  color: #263238;
}

@mixin display-font() {
  @include get-font-config($font-family, 40px, 42px, 300);
}

@mixin headline-font() {
  @include get-font-config($font-family, 24px, 32px, 300);
}

@mixin title-font() {
  @include get-font-config($font-family, 20px, 28px, 400);
}

@mixin subheader-font() {
  @include get-font-config($font-family, 16px, 28px, 400);
}

@mixin body-font() {
  @include get-font-config($font-family, 13px, 18px, 400);
}

@mixin caption-font() {
  @include get-font-config($font-family, 11px, 11px, 300);
}

@mixin button-font() {
  @include get-font-config($font-family, 12px, 22px, 300);
}

.display {
  @include display-font();
}

.headline {
  @include headline-font();
}

.title {
  @include title-font();
}

.subheader {
  @include subheader-font();
}

.body {
  @include body-font();
}

.caption {
  @include caption-font();
}

.button {
  @include button-font();
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.light {
  font-weight: 300;
}

// We don't specify regular because that's our default font-weight
